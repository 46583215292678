@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  margin-top: vars.$gutter;
  flex: 1 1 100%;
  z-index: 1;

  @media screen and (min-width: vars.$extra-large) {
    padding-bottom: 60px;
  }
}

.mainRow {
  align-items: flex-start;
}

.searchTermHeading {
  margin: 0;
  font-size: 1.75rem;

  @media screen and (min-width: vars.$medium) {
    font-size: 2.25rem;
  }

  @media screen and (min-width: vars.$extra-large) {
    font-size: 3rem;
  }

  &Complete {
    text-transform: capitalize;
    font-stretch: condensed;
    font-weight: bold;
  }
}

.results {
  display: grid;
  gap: 16px;

  h1 {
    color: colors.$secondary-text;

    @media screen and (min-width: vars.$extra-large) {
      margin-top: 0;
    }
    span span {
      font-stretch: condensed;
      font-weight: bold;
    }
  }
}

.filtersBtnContainer {
  order: 1;

  b {
    margin-right: 5px;
  }

  p {
    margin-right: 5px;
  }

  @media screen and (min-width: vars.$medium) {
    margin-top: 0;
    order: 4;
  }

  @media screen and (min-width: vars.$extra-large) {
    display: none;
  }

  button {
    @media screen and (max-width: vars.$medium) {
      border-radius: 4px;
    }
  }
}

.filtersIcon {
  margin: 0 10px;
}

.statsAndViewOptionsContainer {
  margin-top: 30px;
}

.sortingOptions {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;
  order: 4;

  li {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.sortingOption {
  align-items: center;
  color: colors.$secondary-text;
  display: flex;
  text-decoration: none;

  &:focus,
  &:hover {
    color: colors.$primary-color;
  }
}

.sortingOptionSelected {
  color: colors.$primary-color;
  font-stretch: condensed;
  font-weight: bold;
}

.sortingOptionDirectionIcon {
  margin-left: 5px;
}

.viewOptions {
  align-items: center;
  display: none;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: flex-end;
  list-style-type: none;
  order: 3;
  padding: 0;

  li:first-child > a {
    border-radius: 4px 0px 0px 4px;
  }
  li:last-child > a {
    border-radius: 0px 4px 4px 0px;
  }

  @media screen and (max-width: vars.$medium) {
    background: colors.$primary-color;
    border-radius: 4px;
    padding: 5px 15px 0 0;
  }
  @media screen and (min-width: vars.$medium) {
    order: 4;
  }

  @media screen and (min-width: vars.$extra-large) {
    display: flex;
    order: 6;
  }
}

.filtersViewOptionsWrapper {
  gap: 16px;
}

.viewOption {
  color: colors.$secondary-text;
  text-decoration: none;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  &:focus,
  &:hover {
    color: colors.$primary-color;
  }
}

.viewOptionSelected {
  color: colors.$white;
  font-stretch: condensed;
  font-weight: bold;

  background-color: colors.$primary-color;
  svg {
    color: white;
  }

  @media screen and (min-width: vars.$medium) {
    color: colors.$primary-color;
  }
}

.resultsCounter {
  align-items: center;
  margin: 0;
  order: 2;
  display: none;

  @media screen and (min-width: vars.$medium) {
    text-align: right;
  }

  @media screen and (min-width: vars.$extra-large) {
    margin-top: 0;
    order: 5;
    align-self: center;
    align-items: center;
    align-content: center;
    display: grid;
  }
}

.product {
  background: colors.$white;
  border-radius: 4px;
  flex: 0 0 100%;
  height: 217px;
  padding: 15px;
  position: relative;
}

.productWishlistBtn {
  background: none;
  border: none;
  color: colors.$secondary-text;
  position: absolute;
  right: 15px;
  top: 15px;

  &.productWishlistSelectedBtn {
    color: colors.$error;
  }
}

.divider {
  background: colors.$grey;
  display: block;
  height: 1px;
  order: 3;
}

.paginationAndSummaryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 30px 0;

  @media screen and (min-width: vars.$medium) {
    flex-direction: row;
    align-items: initial;
  }
  @media screen and (min-width: vars.$extra-large) {
    margin: 32px 0 0;
  }
}

.summary {
  display: none;

  &:first-of-type {
    margin-right: 30px;
  }

  @media screen and (min-width: vars.$medium) {
    align-items: center;
    display: flex;
  }
}

.pagination {
  @media screen and (min-width: vars.$medium) {
    justify-content: right;
    margin-left: auto;
  }
}

@media screen and (min-width: vars.$extra-extra-large) {
  .productDetailsCard {
    grid-column-start: span 15;
  }
}

.viewOptionsColumns {
  @media screen and (min-width: vars.$medium) and (max-width: #{vars.$extra-extra-large - 1}) {
    .productDetailsCard {
      grid-column-start: span 12;
    }
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    .productDetailsCard {
      grid-column-start: span 12;
    }
  }
}

.mainColor {
  background-color: colors.$background;
}

.listCategories {
  padding: 30px 0;
  background: colors.$white;
  @media screen and (min-width: vars.$medium) {
    padding: 60px 0;
  }
  h2 {
    color: colors.$primary-color;
    margin-bottom: 30px;
    b {
      color: colors.$dark-text;
    }
  }

  .selectedSection {
    background: colors.$background;
  }

  .sectionOfCategory {
    margin: 20px 0;
    padding: 0 15px;
    position: relative;

    ul {
      position: relative;
      display: none;

      @media screen and (min-width: vars.$medium) {
        display: block;

        column-count: 4;
        column-gap: 90px;
        padding-bottom: 15px;
      }
      @media screen and (min-width: vars.$extra-large) {
        column-count: 6;
        padding-bottom: 0;
        column-gap: 20px;
      }
    }

    input {
      position: absolute;
      display: inline-block;
      width: 95%;
      margin-top: 0;
      opacity: 0;
      z-index: 1;
      height: 30px;
    }

    .itemOfList {
      list-style: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0px;
    }

    a {
      color: colors.$dark-text;
      text-decoration: none;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: colors.$primary-color;
      }
    }
  }

  .sectionOfCategoryTitle {
    font-stretch: condensed;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    margin-bottom: 5px;
    @media screen and (min-width: vars.$medium) {
      color: colors.$primary-color;
    }

    .plus:after {
      content: '+';
      position: absolute;
      right: 15px;
      font-size: 22px;
      @media screen and (min-width: vars.$medium) {
        display: none;
      }
    }
  }

  .sectionOfCategory input:checked ~ ul {
    display: block;
  }

  .sectionOfCategory input:checked ~ h3 {
    color: colors.$primary-color;
  }

  .sectionOfCategory input:checked ~ h3 .plus:after {
    content: '-';
  }
}

// .filters {
//   display: none;

//   @media screen and (min-width: $extra-large) {
//     display: flex;
//     flex-flow: column;
//     padding-right: 15px;
//     position: sticky;
//     top: 155px;
//     overflow-y: auto;
//     z-index: 3;
//     max-height: calc(100vh - 155px);
//   }

//   @media screen and (min-width: $extra-extra-large) {
//     max-height: calc(100vh - 155px);
//   }
// }

.filters {
  display: none;

  @media screen and (min-width: vars.$extra-large) {
    display: flex;
    // position: sticky;
    // top: 155px;
    z-index: 3;
  }
}

.searchNav {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  justify-content: space-between;

  @media screen and (min-width: vars.$medium) {
    flex-direction: row;
    align-items: center;
  }
}

nav.searchBreadcrumb {
  @media screen and (min-width: vars.$medium) {
    margin-top: 0;
  }
}

.customFilters {
  display: flex !important;
}

.imageSearchTitle {
  color: colors.$grey-default;
  font-stretch: condensed;
}

.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid colors.$grey;
  border-left-color: colors.$primary-color;
  animation: spin 1s linear infinite;
  margin: 40px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.showCategoryLink {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--light-blue);
  font-stretch: condensed;
  text-decoration: underline;
  margin-left: auto;

  @media screen and (min-width: vars.$medium) {
    display: none;
  }
}

.mobileCategoryLink {
  order: 5;
  @media screen and (min-width: vars.$medium) {
    display: none;
  }
}
